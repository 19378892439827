import React, { useEffect } from "react";
import VinhosIn from "./vinhosIn";

const Vinhos = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="margem">
      <VinhosIn />
    </div>
  );
};

export default Vinhos;