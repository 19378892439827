import React from 'react';
import { Carousel } from "react-responsive-carousel";
import Produtos from '../files/Produtos.json';
import Categorias from '../files/Categorias.json';
import * as helper from '../components/helper';

const capa = [
'PREÇOS COM IVA INCLUÍDO À TAXA 23%',
'preços sujeitos a confirmação no estabelecimento'
];

// var year = new Date().getFullYear();
// var mm = new Date().getMonth() + 1; // getMonth() is zero-based
// var dd = new Date().getDate();

// var data = [
//   year,
//   (mm > 9
//     ? ''
//     : '0') + mm,
//   (dd > 9
//     ? ''
//     : '0') + dd
// ].join('');

const settings = {
currency_code: "EUR",
currency_symbol: "€",
currency_format: "{amount}€",
thousand_separator: ".",
decimal_separator: ",",
decimal_number: 2
};

const ItemPrice = ({ product, settings, flag }) => {
  if (product.regular_price === 0.00 && product.price === 0.00) {
    return (
      <div className="menu-item-price">
        &nbsp;
      </div>
    );
  } else {
    if (product.regular_price === product.price || product.price === 0.00) {
        let priceFormatted = helper.formatCurrency(product.regular_price, settings);
        if (flag !== "S") {
          return (
            <div className="menu-item-price">
              {priceFormatted}
            </div>
          );
        } else {
          return (
            <div className="menu-item-price">
                {priceFormatted}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          );
        }
    } else {
       let regularPriceFormatted = helper.formatCurrency(product.regular_price, settings);
       let otherPriceFormatted = helper.formatCurrency(product.price, settings);
       if (flag === "P") {
         return (
             <div className="menu-item-price">
               {regularPriceFormatted}&nbsp;(1PX)&nbsp;&nbsp;{otherPriceFormatted}&nbsp;(2PX)
             </div>
         );
       } else {
         if (product.regular_price !== 0.00 && product.price !== 0.00) {
           return (
             <div className="menu-item-price">
               {regularPriceFormatted}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{otherPriceFormatted}
             </div>
           );
         } else {
            if (product.regular_price !== 0.00) {
              return (
                <div className="menu-item-price">
                  {regularPriceFormatted}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              );
            } else {
              return (
                <div className="menu-item-price">
                  {otherPriceFormatted}
                </div>
              );
            }
         }
       }
    };
  };
};

const ItemName = ({ product, flag }) => {
  var str = product.name;
  var n = str.indexOf(" ");
  var xname = str.substring(n+1);
  var len = xname.length;
  if (flag !== "P") {
  	return (
      <div className="menu-item-name">
        {xname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
  	);
  } else {
    var aux = "";
    if (product.regular_price === product.price || product.price !== 0.00) {
      aux = "X";
    }
    if (len > 35 && aux === "X") {
      return (
        <div>
        <div className="menu-item-name">
          {xname}
        </div>
        <span className="visible-xs"><br /></span>
        </div>
      );
    } else {
      return (
        <div className="menu-item-name">
          {xname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    }
  }
};

const CategTitle = ({ category }) => {
  var str = category.name;
  var xname = str.toUpperCase();
  return (
    <h2 className="menu-section-title">{xname}</h2>
	);
};

const ItemTitle = ({ flag }) => {
  switch(flag) {
    case 'P':
      return null;
    case 'H':
        return (
                <div className="menu-item-price">
                  PÃO&nbsp;&nbsp;&nbsp;PRATO
                </div>
               )
    case 'S':
      return (
              <div className="menu-item-price">
                <br />
                SANDES&nbsp;&nbsp;&nbsp;TOSTA
              </div>
             )
    default:
      return null;
  }
};

// const ItemSubTitle = ({ product, flag }) => {
//    if (flag === "P") {
//      if (product.regular_price === product.price || product.price !== 0.00 ) {
//         return (
//           <div>
//             <div className="menu-item-price">
//               1PX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2PX&nbsp;&nbsp;
//             </div>
//             <br />
//           </div>
//         );
//      } else {
//         return null;
//      }
//    } else {
//      return null;
//    }
// };

// const ItemDummy = () => {
//     return (
//       <div className="menu-item-price">
//         &nbsp;
//       </div>
//     );
// };

const ItemTab =  ({ product }) => {
  if (product.regular_price === 0.00 && product.price === 0.00) {
    return null;
  } else {
  	return (
      <div>
        <div className="menu-item-tab">
        </div>
      </div>
  	)
  }
}

export default class CafetariaIn extends React.Component {

  renderCapa( ) {
    return (
      <div id="restaurant-ementa">
         <div className="section-title text-center center">
            <div className="menu-section">
              {capa.map((value, index) => {
                if (value !== " ") {
                 return (
                   <div key={index} className="menu-item">
                     <div className="menu-item-cover">
                        {value}
                     </div>
                   </div>
                   )
                 } else {
                   return (
                     <div key={index}>
                        <br />
                     </div>
                   )
                 }
              })}
            </div>
        </div>
      </div>
    )
  }

  renderMenu( grupo, flag ) {
    return Produtos.map(function(item, i) {
        if (item.enabled === true && item.category_id === grupo) {
          return (
            <div key={i} className="menu-item">
              <ItemName product={item} flag={flag}/>
              <ItemTab product={item} />
              <ItemPrice product={item} settings={settings} flag={flag}/>
            </div>
          )
        } else {
          return null
        }
      })
  }

  renderGroup( grupo, flag ) {
    return (
      <div id="restaurant-ementa">
        <div className="section-title text-center center">
          <div className="menu-section">
          {Categorias.map((categoria, i) => {
            if (categoria.enabled === true && categoria.id === grupo ) {
              return (
                <div>
                  <div key={i}>
                   <CategTitle category={categoria} />
                    <ItemTitle flag={flag} />
                  </div>
                  {this.renderMenu( grupo, flag )}
                </div>
               )
            } else {
              return null
            }
          })}
          </div>
        </div>
      </div>
    )
  }

  renderDuplo( grupo1, grupo2, flag ) {
    return (
      <div id="restaurant-ementa">
        <div className="section-title text-center center">
          <div className="menu-section">
          {Categorias.map((categoria, i) => {
            if (categoria.enabled === true && categoria.id === grupo1 ) {
              return (
                <div>
                  <div key={i}>
                   <CategTitle category={categoria} />
                   <ItemTitle flag={flag} />
                  </div>
                  {this.renderMenu( grupo1, flag )}
                </div>
               )
            } else {
              return null
            }
          })}
          <br />
          {Categorias.map((categoria, i) => {
            if (categoria.enabled === true && categoria.id === grupo2 ) {
              return (
                <div>
                  <div key={i}>
                   <CategTitle category={categoria} />
                   <ItemTitle flag={flag} />
                  </div>
                  {this.renderMenu( grupo2, flag )}
                </div>
               )
            } else {
              return null
            }
          })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section id="cafetaria" className="margem_s">
        <div id="cafetaria-menu">
          {/* <div className="restaurant-collapse">
            <br /><br />
            <button type="button" className="rci-button rci-toggle collapsed" data-toggle="collapse" data-target="#caflista">
            Cafetaria&nbsp;&nbsp;
            <i className="glyphicon"></i>
            </button>
          </div> */}
          <div id="caflista">
            <div className="overlay">
              <div id="carousel" className="carousel">
              <Carousel showArrows showThumbs={false} infiniteLoop emulateTouch>
              <div>
                <img src="img/cafetaria_bckg.jpg" alt="cafetaria"/>
                <div className="legend">
                {this.renderCapa( )}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="BEBIDAS"/>
                <div className="legend">
                {this.renderGroup("5e458cefb701cd39502091f2", "N")}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="SANDUICHES/TORRADAS"/>
                <div className="legend">
                {this.renderDuplo("5e4589ceb701cd39502091ef", "5e5e818c23c0a5385cb5cf9d", "N")}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="SANDES DIVERSAS E TOSTAS"/>
                <div className="legend">
                {this.renderGroup("5e4182f39d54784d3001eb03", "S")}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="SALGADOS"/>
                <div className="legend">
                {this.renderGroup("5e458a82b701cd39502091f0", "N")}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="DIVERSOS"/>
                <div className="legend">
                {this.renderGroup("5e458d30b701cd39502091f3", "N")}
                </div>
              </div>
              <div>
                <img src="img/cafetaria_bg1.jpg" alt="PASTELARIA VARIADA"/>
                <div className="legend">
                {this.renderGroup("5e458ac1b701cd39502091f1", "N")}
                </div>
              </div>
              </Carousel>
              </div>
            </div>
          </div>
          <div className="restaurant-collapse">
            <br />
            </div>
        </div>
      </section>
    )
  }
}
