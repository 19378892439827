import React from "react";
// const info = [ 'UM MUNDO DE SABORES' ];
const info = [
  "Rua XYZ",
  "9999-999 Estoril",
  "Cascais, Lisboa, Portugal",
  "7:30 às 23:00",
  "Encerra à Quarta-Feira",
  "999 999 999",
  "https://www.facebook.com/pages/category/Portuguese-Restaurant/Flor-de-S%C3%A3o-Jo%C3%A3o-192064580817622/",
];

export default class Contatos extends React.Component {
  render() {
    return (
      <section id="contatos" className="footer_widget">
        <div className="container">
          <div className="row">
            <div className="footer_widget_content text-center">
              <div className="col-md-3">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="2s"
                >
                  <h3>Morada</h3>

                  <div className="single_widget_info">
                    <p>
                      {info[0]}
                      <span>{info[1]}</span>
                      <span>{info[2]}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="4s"
                >
                  <h3>Horário</h3>

                  <div className="single_widget_info">
                    <p>
                      <span>{info[3]}</span>
                      <span>{info[4]}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="4s"
                >
                  <h3>Telefone</h3>

                  <div className="single_widget_info">
                    <p>
                      <span className="phone_email">{info[5]}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="4s"
                >
                  <h3>Social</h3>

                  <div className="footer_social_icon">
                    <a href="/">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </div>
                </div>
                <span className="visible-xs">
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
