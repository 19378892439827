import React, { useEffect } from "react";
// import sectionsInfo from "../files/Sections_en.json";
// import GoTop from "../components/GoTop";

const Policy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="policy">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2>Politica de Privacidade / Privacy Policy</h2>
            <h4 className="text-left">
              DemoRest não recolhe dados pessoais ou informação de qualquer
              outra natureza. Como tal, não processa ou divulga dados e
              informações de forma alguma.
            </h4>
            <h4 className="text-left">
              DemoRest does not collect personal data or information of any
              other nature. As such, it does not process or disclose data and
              information in any way.
            </h4>
            <h2>Politica de Cookies / Cookies Policy</h2>
            <h4 className="text-center">
              DemoRest não usa cookies de qualquer tipo no seu site.
              <br />
              DemoRest does not use cookies of any type on its website.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
