import React, { Component } from "react";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import Categorias from "../files/Categorias.json";
import Produtos from "../files/Produtos.json";
import * as helper from "../components/helper";

const myStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    overflow: "scroll",
    perspective: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },

  content: {
    position: "relative",
    margin: "6% auto",
    // width                   : '40%',
    // height                  : '90%',
    border: "1px solid rgba(0, 0, 0, .2)",
    background: "#fff",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
  },
};

const settings = {
  currency_code: "EUR",
  currency_symbol: "€",
  currency_format: "{amount}€",
  thousand_separator: ".",
  decimal_separator: ",",
  decimal_number: 2,
};

const ItemPrice = ({ product, settings }) => {
  if (product.regular_price === product.price || product.price === 0.0) {
    let priceFormatted = helper.formatCurrency(product.regular_price, settings);
    return <div className="menu-item-price">{priceFormatted}</div>;
  }
};

const ItemName = ({ product }) => {
  var str = product.name;
  var n = str.indexOf(" ");
  var xname = str.substring(n + 1).toUpperCase();
  return <div className="menu-item-name">{xname}</div>;
};

const ItemTab = () => {
  return (
    <div>
      <div className="menu-item-tab"></div>
    </div>
  );
};

// const ItemTitle = ({category}) => {
//   if (category.name === "bar Tinto") {
//     return (
//         <div className="menu-item-price">
//           750 ml
//         </div>
//   )
//   } else {
//     return (
//         <div className="menu-item-price">
//           750 ml&nbsp;&nbsp;&nbsp;350 ml
//         </div>
//     )
//   }
// };

export default class BarIn extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value) {
    const id = value;
    ModalManager.open(<MyModal ident={id} onRequestClose={() => true} />);
  }

  renderbar() {
    return Categorias.map((item, i) => {
      if (
        item.enabled === true &&
        item.parent_id === "5e4176079d54784d3001eaf5"
      ) {
        return (
          <div
            key={"B" + i}
            className="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 bar"
          >
            <div className="thumbnail">
              <a onClick={() => this.handleClick(item)}>
                <span className="bar-img">
                  <img src={item.image} alt={item.name} />
                </span>
              </a>
              <div className="caption">
                <p>{item.name}</p>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <div id="bar" className="margem text-center">
        <div className="overlay">
          <div className="container">
            <div className="col-md-10 col-md-offset-1 section-title">
              <br />
              <br />
              <h2>Bar</h2>
              <h3>
                * bebidas e preços sujeitos a confirmação no estabelecimento *
              </h3>
              <p>&nbsp;</p>
            </div>
            <div id="row">
              {this.renderbar()}
            </div>
          </div>
          <div className="hidden-xl hidden-lg hidden-md">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
        </div>
      </div>
    );
  }
}

class MyModal extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { ident, onRequestClose } = this.props;
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.SlideFromRight}
        style={myStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h5 className="section-heading">{ident.name}</h5>
            <span className="bar-modal-img">
              <img src={ident.image} alt={ident.name} />
            </span>
            <h3 className="section-heading"></h3>
          </div>
          <div className="modal-body text-center">
            <div id="restaurant-ementa">
              {Produtos.map((item, i) => {
                if (item.enabled === true && item.category_id === ident.id) {
                  return (
                    <div key={i} className="menu-item">
                      <ItemName product={item} />
                      <span className="hidden-md hidden-lg hidden-xl">
                        <br />
                      </span>
                      <ItemTab />
                      <ItemPrice product={item} settings={settings} />
                      <br />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-xs" onClick={ModalManager.close}>
              X
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
