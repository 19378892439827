import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import slider from "../files/Slider.json";

const Slides = () => {
  return (
    <div className="container-fluid">
      <Carousel
        showThumbs={false}
        showStatus={false}
        // showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        // dynamicHeight={true}
      >
        {slider.map((item, index) => {
          if (item.width === "") {
            return (
              <div key={"S" + index}>
                <img src={item.image} alt={item.title} className="img-fluid"/>
                {/* <p className="legend">{item.title}</p> */}
              </div>
            );
          } else {
            return (
              <div key={"S" + index}>
                <img
                  src={item.image}
                  alt={item.title}
                  style={{ width: 450, height: 650 }}
                />
                <p className="legend">{item.title}</p>
              </div>
            );
          }
        })}
      </Carousel>
      <br />
    </div>
  );
};

const Slider = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Slides />
        </div>
      </div>
    </div>
  );
};

export default Slider;
