import React, { useEffect } from "react";
import MenuIn from "./menuIn";

const Menu = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="margem">
      <MenuIn />
    </div>
  );
};

export default Menu;
