import React, {useEffect} from 'react';
import Hero from "../components/Hero";
import Mapa from "../components/Mapa";
import About from "./about_us";
import Apresentar from './apresentar';
import Extra from '../pages/extra';
import Info from "./info";

const Landing = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <div>
      <Hero />
      <About />
      <Apresentar />
      <Extra />
      <Info />
      <Mapa />
    </div>
  )
}

export default Landing;
