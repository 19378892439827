import React from 'react';

var valini = "";
var valend = "";

var year = new Date().getFullYear();
var mm = new Date().getMonth() + 1; // getMonth() is zero-based
var dd = new Date().getDate();

valini = valini.concat(year).concat("1201");
valend = valend.concat(year).concat("1224");

var data = [
  year,
  (mm > 9
    ? ''
    : '0') + mm,
  (dd > 9
    ? ''
    : '0') + dd
].join('');

export default class Extra extends React.Component {
  render() {
    if (data >= valini && data <= valend) {
       return (
          <section id="extra">
          <div className="extras">
            <div className="container">
              <div className="extras_content">
                 <img src="img/Flor_SJ_Natal.jpg" className="img-responsive" alt="Encomendas de Natal" />
               </div>
            </div>
            </div>
          </section>
          )
        } else {
          return null
        }
  }
}
