import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import Produtos from "../files/Produtos.json";
import Categorias from "../files/Categorias.json";
import * as helper from "../components/helper";

const capa = [
  "PREÇOS COM IVA INCLUÍDO À TAXA 23%",
  "preços sujeitos a confirmação no estabelecimento",
];

const imagens = ["img/capa.jpg", "img/menu-bg-1.jpg"];

// var year = new Date().getFullYear();
// var mm = new Date().getMonth() + 1; // getMonth() is zero-based
// var dd = new Date().getDate();

// var data = [
//   year,
//   (mm > 9
//     ? ''
//     : '0') + mm,
//   (dd > 9
//     ? ''
//     : '0') + dd
// ].join('');

const myStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    overflow: "scroll",
    perspective: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },

  content: {
    position: "relative",
    margin: "6% auto",
    // width                   : '40%',
    // height                  : '90%',
    border: "1px solid rgba(0, 0, 0, .2)",
    background: "#fff",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
  },
};

const settings = {
  currency_code: "EUR",
  currency_symbol: "€",
  currency_format: "{amount}€",
  thousand_separator: ".",
  decimal_separator: ",",
  decimal_number: 2,
};

const ItemPrice = ({ product, settings, flag }) => {
  if (product.regular_price === 0.0 && product.price === 0.0) {
    return <div className="menu-item-price">&nbsp;</div>;
  } else {
    if (product.regular_price === product.price || product.price === 0.0) {
      let priceFormatted = helper.formatCurrency(
        product.regular_price,
        settings
      );
      return <div className="menu-item-price">{priceFormatted}</div>;
    } else {
      let regularPriceFormatted = helper.formatCurrency(
        product.regular_price,
        settings
      );
      let otherPriceFormatted = helper.formatCurrency(product.price, settings);
      if (flag === "P") {
        return (
          <div className="menu-item-price">
            {regularPriceFormatted}&nbsp;(1PX)&nbsp;&nbsp;{otherPriceFormatted}
            &nbsp;(2PX)
          </div>
        );
      } else {
        return (
          <div className="menu-item-price">
            {regularPriceFormatted}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {otherPriceFormatted}
          </div>
        );
      }
    }
  }
};

const ItemName = ({ product, flag }) => {
  var str = product.name;
  var n = str.indexOf(" ");
  var xname = str.substring(n + 1);
  var len = xname.length;
  if (flag !== "P") {
    return (
      <div className="menu-item-name">
        {xname}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  } else {
    var aux = "";
    if (product.regular_price === product.price || product.price !== 0.0) {
      aux = "X";
    }
    if (len > 35 && aux === "X") {
      return (
        <div>
          <div className="menu-item-name">{xname}</div>
          <span className="visible-xs">
            <br />
          </span>
        </div>
      );
    } else {
      return (
        <div className="menu-item-name">
          {xname}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    }
  }
};

const CategTitle = ({ category }) => {
  var str = category.name;
  var xname = str.toUpperCase();
  return <h2 className="menu-section-title">{xname}</h2>;
};

const ItemTitle = ({ flag }) => {
  switch (flag) {
    case "P":
      return null;
    // break;
    case "H":
      return <div className="menu-item-price">PÃO&nbsp;&nbsp;&nbsp;PRATO</div>;
    // break;
    case "S":
      return (
        <div className="menu-item-price">SANDES&nbsp;&nbsp;&nbsp;TOSTA</div>
      );
    // break;
    default:
      return null;
  }
};

const ItemTab = ({ product }) => {
  if (product.regular_price === 0.0 && product.price === 0.0) {
    return null;
  } else {
    return (
      <div>
        <div className="menu-item-tab"></div>
      </div>
    );
  }
};

export default class MenuIn extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value) {
    const id = value;
    ModalManager.open(<ModalExtras ident={id} onRequestClose={() => true} />);
  }

  renderCapa() {
    return (
      <div id="restaurant-ementa">
        <div className="section-title text-center center">
          <div className="menu-section">
            {capa.map((value, index) => {
              if (value !== " ") {
                return (
                  <div key={"C" + index} className="menu-item">
                    <div className="menu-item-cover">{value}</div>
                  </div>
                );
              } else {
                return (
                  <div key={"C" + index}>
                    <br />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }

  renderMenu(grupo, flag) {
    return (
      <>
        {Produtos.map((item, i) => {
          if (item.enabled === true && item.category_id === grupo) {
            return (
              <div key={"P" + i} className="menu-item">
                <ItemName product={item} flag={flag} />
                <ItemTab product={item} />
                <ItemPrice product={item} settings={settings} flag={flag} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }

  renderGroup(grupo, flag) {
    return (
      <div id="restaurant-ementa">
        <div className="section-title text-center center">
          <div className="menu-section">
            {Categorias.map((categoria, i) => {
              if (categoria.enabled === true && categoria.id === grupo) {
                return (
                  <div>
                    <div key={"M" + i}>
                      <CategTitle category={categoria} />
                      <ItemTitle flag={flag} />
                    </div>
                    {this.renderMenu(grupo, flag)}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    );
  }

  renderDuplo(grupo1, grupo2, flag) {
    return (
      <div id="restaurant-ementa">
        <div className="section-title text-center center">
          <div className="menu-section">
            {Categorias.map((categoria, i) => {
              if (categoria.enabled === true && categoria.id === grupo1) {
                return (
                  <div>
                    <div key={"G1" + i}>
                      <CategTitle category={categoria} />
                      <ItemTitle flag={flag} />
                    </div>
                    {this.renderMenu(grupo1, flag)}
                  </div>
                );
              } else {
                return null;
              }
            })}

            {Categorias.map((categoria, i) => {
              if (categoria.enabled === true && categoria.id === grupo2) {
                return (
                  <div>
                    <div key={"G2" + i}>
                      <CategTitle category={categoria} />
                      <ItemTitle flag={flag} />
                    </div>
                    {this.renderMenu(grupo2, flag)}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    );
  }

  renderPratosDia(id) {
    return Categorias.map((item, i) => {
      if (item.enabled === true && item.id === id) {
        return (
          <div
            key={"PD" + i}
            className="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2"
          >
            <div className="thumbnail">
              <a onClick={() => this.handleClick(item)}>
                <span className="menu-img">
                  <img src={item.image} alt={item.name} />
                </span>
              </a>
              <div className="caption">
                <p>Pratos/dia</p>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  renderSobremesas(id) {
    return Categorias.map((item, i) => {
      if (item.enabled === true && item.id === id) {
        return (
          <div
            key={"SO" + i}
            className="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2"
          >
            <div className="thumbnail">
              <a onClick={() => this.handleClick(item)}>
                <span className="menu-img">
                  <img src={item.image} alt={item.name} />
                </span>
              </a>
              <div className="caption">
                <p>{item.name}</p>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  renderFrutas(id) {
    return Categorias.map((item, i) => {
      if (item.enabled === true && item.id === id) {
        return (
          <div
            key={"F" + i}
            className="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2"
          >
            <div className="thumbnail">
              <a onClick={() => this.handleClick(item)}>
                <span className="menu-img">
                  <img src={item.image} alt={item.name} />
                </span>
              </a>
              <div className="caption">
                <p>Fruta/dia</p>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  renderExtras() {
    return (
      <div className="container text-center">
        <div className="row">
          <div className="col-xs-0 col-sm-0 col-md-1 col-lg-1 col-xl-1"></div>
          {this.renderPratosDia("5e417eb89d54784d3001eaff")}
          {this.renderSobremesas("5e4180879d54784d3001eb00")}
          {this.renderFrutas("5e43e1ed8cb3131258ba1d9a")}
        </div>
      </div>
    );
  }

  render() {
    return (
      <section id="menu" className="margem_s">
        <div id="restaurant-menu">
          {/* <div className="restaurant-collapse">
            <br /><br />
            <button type="button" className="rci-button rci-toggle collapsed" data-toggle="collapse" data-target="#lista">
            Menu&nbsp;&nbsp;
            <i className="glyphicon"></i>
            </button>
          </div> */}
          {/* <div id="lista"> */}
          <div className="overlay">
            <div id="carousel" className="carousel">
              <Carousel showArrows showThumbs={false} infiniteLoop emulateTouch>
                <div>
                  <img src={imagens[0]} alt="comidas" />
                  <div className="legend">{this.renderCapa()}</div>
                </div>
                <div>
                  <img src={imagens[1]} alt="ementa turística" />
                  <div className="legend">
                    {this.renderGroup("5e4179509d54784d3001eaf9", "T")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Petiscos/Entradas" />
                  <div className="legend">
                    {this.renderGroup("5e4178e09d54784d3001eaf8", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Omoletas" />
                  <div className="legend">
                    {this.renderGroup("5e4177569d54784d3001eaf6", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Saladas" />
                  <div className="legend">
                    {this.renderGroup("5e4178139d54784d3001eaf7", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Peixe" />
                  <div className="legend">
                    {this.renderGroup("5e4179a09d54784d3001eafa", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Especialidades de Peixe" />
                  <div className="legend">
                    {this.renderGroup("5e417a3a9d54784d3001eafb", "P")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Carne de Porco" />
                  <div className="legend">
                    {this.renderGroup("5e4173b69d54784d3001eaf2", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Carne de Vaca" />
                  <div className="legend">
                    {this.renderGroup("5e417a9c9d54784d3001eafc", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Carne de Perú" />
                  <div className="legend">
                    {this.renderGroup("5e417b019d54784d3001eafd", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Especialidades de Carne" />
                  <div className="legend">
                    {this.renderGroup("5e417d009d54784d3001eafe", "N")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Hamburgers" />
                  <div className="legend">
                    {this.renderGroup("5e41814e9d54784d3001eb01", "H")}
                  </div>
                </div>
                <div>
                  <img src={imagens[1]} alt="Pregos / Bifana / Francezinha" />
                  <div className="legend">
                    {this.renderDuplo(
                      "5e4182be9d54784d3001eb02",
                      "5e5e7e4423c0a5385cb5cf9b",
                      "N"
                    )}
                  </div>
                </div>
              </Carousel>
              <br />
              {this.renderExtras()}
            </div>
          </div>
        </div>
        <div className="restaurant-collapse">
          <br />
        </div>
        {/* </div> */}
      </section>
    );
  }
}

class ModalExtras extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { ident, onRequestClose } = this.props;
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.SlideFromRight}
        style={myStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h2 className="section-heading">{ident.name}</h2>
            <span className="menu-modal-img">
              <img src={ident.image} alt={ident.name} />
            </span>
            <br />
            <h3 className="section-heading">
              consultar oferta diária no estabelecimento
            </h3>
          </div>
          <div className="modal-body text-center">
            <div id="restaurant-ementa">
              <ItemTitle category={ident} />
              {Produtos.map((item, i) => {
                if (item.enabled === true && item.category_id === ident.id) {
                  return (
                    <div key={"MP" + i} className="menu-item">
                      <ItemName product={item} flag="N" />
                      <span className="hidden-md hidden-lg hidden-xl">
                        <br />
                      </span>
                      <ItemTab product={item} />
                      <ItemPrice product={item} settings={settings} flag="N" />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-xs" onClick={ModalManager.close}>
              X
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
