import * as React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./styles/hamburger.scss";
import "./styles/carousel.css"; // change this to the file path of your overrides
// import "./styles/font-awesome.min.css";
import "./styles/bootstrap.min.css";
import "./styles/animate/animate.css";
import "./styles/plugins.css";
import "./styles/responsive.css";
import "./styles/style.css";
import "./styles/responsive.css";
// import "./styles/skins/tango/skin.css";
import "./styles/theme.css";
import "./styles/imports.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/about";
import Landing from "./pages/Landing";
import Apresenta from "./pages/apresenta";
import Menu from "./pages/menu";
import Contatos from "./pages/contatos";
import Cafetaria from "./pages/cafetaria";
import Bar from "./pages/bar";
import Vinhos from "./pages/vinhos";
import Policy from "./pages/Policy";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="Landing" element={<Landing />} />
        <Route path="about" element={<About />} />
        <Route path="apresenta" element={<Apresenta />} />
        <Route path="menu" element={<Menu />} />
        <Route path="vinhos" element={<Vinhos />} />
        <Route path="bar" element={<Bar />} />
        <Route path="cafetaria" element={<Cafetaria />} />
        <Route path="contatos" element={<Contatos />} />
        <Route path="policy" element={<Policy />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;


