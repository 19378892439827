import React from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "./Hamburger";

const info = ["7:30 às 23", "999 999 999"];

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-default navbar-fixed-top">
        {/* <div className='preloader'><div className='loaded'>&nbsp;</div></div> */}
        <div className="header_top_menu clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-offset-3 col-sm-8 col-xs-12 text-right">
                <div className="call_us_text">
                  <Link to="">
                    <i className="fa fa-clock-o"></i>
                    {info[0]}
                  </Link>
                  <Link to="">
                    <i className="fa fa-phone"></i>
                    {info[1]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_menu_bg">
          <div className="container">
            <div className="navbar-header page-scroll">
              <Link to="/" className="navbar-brand our_logo">
                <img src="img/logo-demo.png" alt="" />
              </Link>
            </div>
            <div className="container-fluid">
              <div className="hidden-sm hidden-xs">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Link to="about">Quem Somos</Link>
                  </li>
                  <li>
                    <Link to="apresenta">Apresentação</Link>
                  </li>
                  <li>
                    <Link to="menu">Menu</Link>
                  </li>
                  <li>
                    <Link to="vinhos">Vinhos</Link>
                  </li>
                  <li>
                    <Link to="cafetaria">Cafetaria</Link>
                  </li>
                  <li>
                    <Link to="bar">Bar</Link>
                  </li>
                  <li>
                    <Link to="contatos">Contatos</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex hidden-md hidden-lg hidden-xl">
              <HamburgerMenu />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
