import React from 'react';

export default class Mapa extends React.Component {
  render() {
    return (
      <div id="map">
        <iframe title="mapa" width="100%" height="310" src="https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Praceta+Gil+Vicente+São+João+do+Estori&sll=38.6993475,-9.3838128,17z&4&output=embed"></iframe>
      </div>
    )
  }
}
