import React from 'react';

const info = [ 'Restaurante de 3ª Categoria' ];
const texto = [
'Especialistas em Restauração',
'Ambiente Hospitaleiro',
'Cozinha Regional e Pratos Especiais',
'Pastelaria Variada e Bar Completo',
];

export default class AboutUs extends React.Component {
  render() {
    return (
      <section id="sobre" className="abouts">
          <div className="container">
              <div className="row">
                  <div className="abouts_content">
                      <div className="col-md-6">
                          <div className="single_abouts_text text-center wow slideInLeft" data-wow-duration="1s">
                              <img src="img/about.png" alt="" />
                          </div>
                      </div>

                      <div className="col-md-6">
                          <div className="single_abouts_text wow slideInRight" data-wow-duration="1s">
                              <h3>Quem Somos</h3>
                              <h4>{info.slice(0, 1).join(' ')}</h4>
                              <p style={{ fontSize: 20 }}>{texto.slice(0, 1).join(' ')}</p>
                              <p style={{ fontSize: 20 }}>{texto.slice(1, 2).join(' ')}</p>
                              <p style={{ fontSize: 20 }}>{texto.slice(2, 3).join(' ')}</p>
                              <p style={{ fontSize: 20 }}>{texto.slice(3, 4).join(' ')}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

    )
  }
}
