import React from 'react';

const extra = [
'O que pode encomendar para o Natal'
];

var valini = "";
var valend = "";

var year = new Date().getFullYear();
var mm = new Date().getMonth() + 1; // getMonth() is zero-based
var dd = new Date().getDate();

valini = valini.concat(year).concat("1201");
valend = valend.concat(year).concat("1224");

var data = [
  year,
  (mm > 9
    ? ''
    : '0') + mm,
  (dd > 9
    ? ''
    : '0') + dd
].join('');

export default class Home extends React.Component {
  render() {
    return (
      <section id="slider" className="slider">
        <div className="slider_overlay">
          <div className="container">
              <div className="row">
                  <div className="main_slider text-center">
                      <div className="col-md-12">
                          <div className="main_slider_content wow zoomIn" data-wow-duration="1s">
                              <h1>Demo Restaurante</h1>
                              <h2>Restaurante Bar</h2>
                              <br />
                              <a href="#sobre" className="page-scroll btn-lg">Quem Somos</a>
                              <br /><br /><br /><br />
                              {extra.map((value, index) => {
                                 if (data >= valini && data <= valend) {
                                 return (
                                   <div key={index}>
                                    <span className="extras_link text-responsive">
                                     <p><a href="#extra" className="page-scroll">{value}</a></p>
                                     </span>
                                    </div>
                                   )
                                 } else {
                                   return null
                                 }
                              })}
                              <br /><br />
                              <p className="construct">* Site Demonstração *</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    )
  }
}

