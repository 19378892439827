import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
// import { elastic as Menu } from "react-burger-menu";

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

const Burger = () => {
  const ctx = useContext(MyContext);

  return (
    <Menu right
      customBurgerIcon={<HamburgerIcon />}
      width={"auto"}
      className="left-0 top-12"
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    >
      <Links />
    </Menu>
  );
};

const HamburgerIcon = () => (
  <div className="p-1/2">
    <svg
      className="w-8 h-8 text-gray-500"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
  </div>
);

export const Links = () => {
  const ctx = useContext(MyContext);

  return (
    <div>
      <Link to="/" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Home</span>
      </Link>
      <br />
      <Link to="about" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Quem Somos</span>
      </Link>
      <br />
      <Link to="apresenta" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Apresenta</span>
      </Link>
      <br />
      <Link to="menu" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Menu</span>
      </Link>
      <br />
      <Link to="vinhos" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Vinhos</span>
      </Link>
      <br />
      <Link to="cafetaria" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Cafetaria</span>
      </Link>
      <br />
      <Link to="bar" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Bar</span>
      </Link>
      <br />
      <Link to="contatos" onClick={ctx.toggleMenu}>
        <span className="cursor-pointer underline pb-3 mb-3">Contatos</span>
      </Link>
    </div>
  );
};

const HamburgerMenu = () => {
  return (
    <MyProvider>
      <Burger />
    </MyProvider>
  );
};

export default HamburgerMenu;
