import React, { useEffect } from "react";
import Apresentar from "./apresentar";

const Apresenta = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="margem">
      <Apresentar />
    </div>
  );
};

export default Apresenta;
