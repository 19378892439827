import React from 'react';

const info1 = [
'Cozinha Portuguesa',
'Pastelaria',
'Refeições casuais',
'Ementa turística',
'Menu de almoço',
'Bar completo',
'Vinho a copo'
];

const info2 = [
'Esplanada',
'Interior',
'Tem wifi',
'Canais desportivos',
'Há livro de reclamações',
];

const info3 = [
'20 € para duas pessoas (aprox.)',
'Genérico',
'17 € para duas pessoas (aprox.)',
'Para menu de almoço',
'1 €  por uma cerveja (aprox.)',
'Dinheiro e Cartões aceites',
];


// var year = new Date().getFullYear();
// var mm = new Date().getMonth() + 1; // getMonth() is zero-based
// var dd = new Date().getDate();

// var data = [
//   year,
//   (mm > 9
//     ? ''
//     : '0') + mm,
//   (dd > 9
//     ? ''
//     : '0') + dd
// ].join('');

// var i;

export default class Apresenta extends React.Component {
  render() {
    return (
      <section id="apresenta">
        <div className="ourPakeg">
          <div className="container">
              <div className="main_pakeg_content">
                  <div className="row">
                      <div className="head_title text-center">
                          <h2>Apresentação</h2>
                      </div>

                      <div className="single_pakeg_one text-right wow rotateInDownRight">
                          <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4">
                              <div className="single_pakeg_text">
                                  <div className="pakeg_title">
                                      <h3>Caraterísticas</h3>
                                  </div>
                                  <ul>
                                      {info1.map((value, index) => {
                                        return ( <li key={index}>
                                                 <div><i className="fa fa-check-square-o"></i> {value}</div>
                                                 </li> )
                                        })}
                                  </ul>
                              </div>
                          </div>
                      </div>

                      <div className="single_pakeg_two text-left wow rotateInDownLeft">
                          <div className="col-md-6 col-sm-8">
                              <div className="single_pakeg_text">
                                  <div className="pakeg_title">
                                      <h3>Regalias</h3>
                                  </div>
                                  <ul>
                                      {info2.map((value, index) => {
                                        return ( <li key={index}>
                                                 <div><i className="fa fa-thumbs-up"></i> {value}</div>
                                                 </li> )
                                        })}
                                  </ul>
                              </div>
                          </div>
                      </div>

                      <div className="single_pakeg_three text-right wow rotateInDownRight">
                          <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4">
                              <div className="single_pakeg_text">
                                  <div className="pakeg_title">
                                      <h3>Preço Médio</h3>
                                  </div>
                                  <ul>
                                      {info3.map((value, index) => {
                                        if ( index === 0 || index === 2 || index === 4 ) {
                                        return ( <li key={index}>
                                                 <div><i className="fa fa-info-circle"></i> {value}</div>
                                                 </li> )
                                        } else {
                                          return ( <li key={index}>
                                                   {value}
                                                   </li> )
                                        }
                                        })}
                                  </ul>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
          </div>
      </section>
    )
  }
}
