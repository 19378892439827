import React from "react";
import { Link } from "react-router-dom";

var year = new Date().getFullYear();

const info = [
  "Demo Restaurante",
];

export default class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer id="footer" className="footer">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright wow zoomIn" data-wow-duration="3s">
                  <p>
                    &copy; {year} {info[0]}
                    <br />
                    <Link to="policy">Politica de Privacidade e Cookies</Link>
                    <br />
                    Adaptado por{" "}
                    <a
                      href="https://resolution.pt"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Resolution - Consultores Informáticos, Lda.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
