import React from "react";
import Info from "./info";
import Mapa from "../components/Mapa";

export default class Contatos extends React.Component {
  render() {
    return (
      <div className="margem">
        <Info />
        <Mapa />
      </div>
    );
  }
}
