import React, { useEffect } from "react";
import AboutUs from "./about_us";
import Slider from "./slider";
import Info from "./info";

const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="margem">
      <AboutUs />
      <span className="about">
        <Slider />
      </span>
      <Info />
    </div>
  );
};

export default About;
